import React, { createContext, useEffect, useState } from 'react';
import LoginPageLayoutWrapper from '../../Components/LoginPageLayoutWrapper/loginPageLayoutWrapper';
import BasicInfo from './OnboardForms/BasicInfo';
import './AgentCreation.scss';
import VerifyCode from './OnboardForms/VerifyCode';
import BusinessInfo from './OnboardForms/BusinessInfo';
import PersonalInfo from './OnboardForms/PersonalInfo';
import BankInfo from './OnboardForms/BankInfo';
import DocumentUpload from './OnboardForms/DocumentUpload';
import UtilityUpload from './OnboardForms/UtilityUpload';
import Identification from './OnboardForms/Identification';
import BvnInfo from './OnboardForms/BvnInfo';
import axios from 'axios';
import baseUrl from '../../baseUrl';
import swal from 'sweetalert';
import Modal from '../../Components/UI/Modal/Modal';
import BvnPhoneInput from './OnboardForms/BvnPhoneInput';
import VerifyEmail from './OnboardForms/VerifyEmail';
import AgentReferral from './OnboardForms/AgentReferral';
import { useHistory } from 'react-router';


export const AgentCreationContext = createContext();
const AgentCreation = () => {
    const [state, setState] = useState({
        step: 1,
        states:[],
        stateLga:[],
        activePhoneNumber:'',
        bvnPhoneNumber:'',
        bvn:'',
        stateOfOrigin:'',
        address:'',
        lga:'',
        email:'',
        gender:'',
        firstName:'',
        lastName:'',
        middleName:'',
        parentUsername:'',
        settlementAccountNo:'',
        settlementBank:'',
        smsOtp:'',
        emailOtp:'',
        hash:'',
        businessName:'',
        businessLocation:'',
        businessState:'',
        businessLga:'',
        businessLandmark:'',
        identificationType:'',
        idCard:'',
        idCardBack:'',
        idNumber:'',
        picture:'',
        signature:'',
        utilityBill:'',
        codeVerified: true,
        newPhone: false,
        faq: false,
        referral: false,
        emailVerify: false,
        submit:false,
        otpError: false,
        otpSuccess: false,
        countcheck: true,
        bvnError: false,
        validatedButton: false,
        disabled:false,
        nameValidation: true,
        terms: false,
        referralCheck: false,
        upload: false
    })

    const history = useHistory();
    const {step, states, smsOtp, emailOtp, newPhone, bvnPhoneNumber, email, activePhoneNumber, settlementAccountNo, terms, referral, submitref, faq} = state;

    useEffect(()=>{

        // Getting all states and Lgas
        axios.get('stateandlga.json')
        .then(response=>{
            setState(state=>({
                ...state,
                states:response.data
            }))
            // console.log(state.states)
        })
    }, [])

    const onClickNext = (e) =>{
        e.preventDefault();
        let currentStep = state.step;
        currentStep = currentStep >= 8 ? 9 : currentStep + 1
        setState(state=>({
            ...state,
            step: currentStep
        }))
        // if (step === 1) {

        // }

    }

    const onClickPrev = (e) =>{
        e.preventDefault();
        let currentStep = state.step;
        currentStep = currentStep <=1 ? 1 : currentStep - 1
        setState(state=>({
            ...state,
            step: currentStep
        }))
        // if (step === 1) {

        // }

    }

    const onChange =(e)=>{
        let name = e.target.name;
        let value = e.target.value;
        setState({
            ...state,
           [ e.target.name]: e.target.value
        })
  
        if (name==="stateOfOrigin" || name==="businessState"){
            getLgaByState(value)
        }
    }

    const onFileChange = (event) => {
        // debugger;
        setState({
            ...state,
            upload: true
        })
        const cloudName = 'ds2ypqdcv';
        let file = event.target.files[0];
        let nameOfField = event.target.name;
        let reader = new FileReader();
        let formData = new FormData();
        if (file) {
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
                formData.append("file", e.target.result);
                formData.append("upload_preset", 'freedom')
                axios({
                    url:`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
                    method: 'post',
                    data: formData
                }).then(resp=>{
                    setState({
                        ...state,
                        upload: false
                    })
                    if(resp.status === 200){
                        setState({
                            ...state,
                            // [nameOfField]: reader.result
                            [nameOfField]: resp.data.secure_url
                        })
                    }else{
                        swal("Ooops!", "An error occured while uploading image", "error")
                    }
                })

            }
        }
    }

    // const cloudUpload = () =>{
    //     // debugger;

    //     const formData = new FormData();
    //     // const files = [state.idCardBack, state.idCard]

    //     // for(let i = 0; i< files.length; i++){
    //     //     let file = files[i];
    //         formData.append("file", state.idCardBack);
    //         formData.append("file", state.idCard);
    //         formData.append("upload_preset", 'ogz2ea0q')
        
    //     // const preset = '()'
    //     // let reqBody = {
    //     //     idCard : state.idCard,
    //     //     idCardBack : state.idCardBack
    //     // }


    //         axios({
    //             url:`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
    //             method: 'post',
    //             data: formData
    //         }).then(resp=>{
    //             console.log(resp)
    //         })
    //     // }
    // }

    //Function to get the local government tied to a state
    const getLgaByState = (name)=>{
        // Inside array states, find the state that matches the value of state that was sent from the state input field
        // debugger;
        let value = states.find(state => state.state.name.toLowerCase().match(name.toLowerCase()));

        if(value){
            setState(state=>({
                ...state,
                stateLga: value.state.locals
            }))
        }else{
            setState(state=>({
                ...state,
                stateLga: []
            }))
        }
    }

    //This is to check when the BVN Phone number field reaches 11 values
    const checkBVNLength = () => {
        if (bvnPhoneNumber.length >= 11) {
          setState(state=>({
              ...state,
              countcheck: false
          }))
        }else{
            setState(state=>({
                ...state,
                countcheck: true
            })) 
        }
    }
      // useEffect hook runs when a bvn phone number value is changed
      
    useEffect(() => {
        checkBVNLength();
    }, [bvnPhoneNumber]);

    const validateBvn = (e) =>{
        e.preventDefault();
       
        const {firstName, lastName, middleName, activePhoneNumber, bvn, bvnPhoneNumber} = state;
        // if(firstName.trim()==='' || lastName.trim()==='' || middleName.trim()==='' || activePhoneNumber.trim()==='' || bvn.trim()===''){
        //     swal("Hold on!", "Please fill all fields", "info")
        // }
        // else{
            setState(state=>({
                ...state,
                submit: true,
                bvnError: false,
                smsOtp:'',
                otpError: false,
                otpSuccess:false,
                codeVerified: true
            }))
            const reqBody = {
                activePhoneNumber,
                bvnPhoneNumber,
                bvn,
                dateOfBirth:''
            }

            axios({
                url: `${baseUrl}/bvn/bvn-details-validate`,
                method:'post',
                headers:{
                    'Content-Type': 'application/json'
                },
                data: reqBody
            })
            .then(response=>{
                    if(response.data.respCode === '00'){
                        const {hash} = response.data.body
                        setState(state=>({
                            ...state,
                            hash,
                            step:2,
                            submit: false,
                            newPhone: false
                        }))
                    }else if(response.data.respCode === '98'){
                        setState(state=>({
                            ...state,
                            newPhone: true,
                            submit: false,
                        }))

                        //Adding this if check to control how the error displays in the modal. The error should show only after the user has tried putting in another phone number in the modal
                        if(bvnPhoneNumber !== ''){
                            setState(state=>({
                                ...state,
                                bvnError: true,
                                // bvnPhoneNumber:''
                            }))
                        }
                    }else if(response.data.respCode === '99'){
                        swal("Oops!", `${response.data.respDesc}`, "info")
                        setState(state=>({
                            ...state,
                            submit: false
                        }))
                    }
            })
            .catch(err => {
                swal("An error occured", `${err.message}`, "error")
                setState(state=>({
                    ...state,
                    submit: false
                }))
            });
        // }
    }

    const verifyOtp = (otp, value)=>{
        setState(state=>({
            ...state,
            submit: true,
            otpError: false,
            otpSuccess: false
        }))
        const {hash} = state;
        let reqBody = {
            hash,
            otp,
            userValue: value
        }
        axios({
            url: `${baseUrl}/bvn/otp-confirmations`,
            method:'post',
            headers:{
                'Content-Type': 'application/json'
            },
            data: reqBody 
        }).then(response=>{
            if(response.data.respCode=== '00'){
                setState(state=>({
                    ...state,
                    codeVerified: false,
                    otpSuccess: true,
                    submit: false
                }))
            }else if(response.data.respCode === '99'){
                setState(state=>({
                    ...state,
                    otpError: true,
                    submit: false,
                    codeVerified: true
                }))
            }
            
        })
        .catch(err => {
            swal("An error occured", `${err.message}`, "error")
            setState(state=>({
                ...state,
                submit: false
            }))
        });
    }

    const validateEmail = (e)=>{
        e.preventDefault();
       
        const {email, address, lga, stateOfOrigin, gender} = state;
        // if(email.trim()==='' || address.trim()==='' || stateOfOrigin.trim()==='' || lga.trim()==='' || gender.trim()===''){
        //     swal("Hold on!", "Please fill all fields", "info")
        // }
        // else{
            setState(state=>({
                ...state,
                submit: true,
                otpError:false,
                otpSuccess: false,
                emailOtp:'',
                codeVerified: true
            }))
            axios({
                url: `${baseUrl}/bvn/email-validate?email=${email}`,
                method:'post',
                headers:{
                    'Content-Type': 'application/json'
                }
            }).then(response=>{
                if(response.data.respCode === '00'){
                    setState(state=>({
                        ...state,
                        hash: response.data.body,
                        step:5,
                        submit: false
                    }))
                }else if(response.data.respCode === '99'){
                    setState(state=>({
                        ...state,
                        submit: false
                    }))
                   swal("Ooops!", `${response.data.respDesc}`, "error")
                }
            }).catch(err => {
                swal("An error occured", `${err.message}`, "error")
                setState(state=>({
                    ...state,
                    submit: false
                }))
            });
        // }
            // console.log(state.hash)
    }

    //This is to check when the OTP field reaches 4 values

    const checkOTPCount = (otp, value) => {
        if (otp.length >= 4) {
          verifyOtp(otp, value)
        }
    }
      // useEffect hook runs when a OTP value is changed
      
    useEffect(() => {
        checkOTPCount(smsOtp, activePhoneNumber);
    }, [smsOtp]);

    useEffect(() => {
        checkOTPCount(emailOtp, email);
    }, [emailOtp]);

    const cancelAddNumber =()=>{
        setState(state=>({
            ...state,
            newPhone: false,
            bvnPhoneNumber:''
        }))
    }

    const onClickBvn = ()=>{
        setState(state=>({
            ...state,
            faq: true
        }))
    }

    const cancelFaq =()=>{
        setState(state=>({
            ...state,
            faq: false
        }))
    }

    const onCheckReferral = () =>{
        setState(state=>({
            ...state,
            referral: true,
            referralCheck: true
        }))
    }

    const cancelAddReferral =()=>{
        if(submitref){
            setState(state=>({
                ...state,
                referral: false,
            }))
        }else{
            setState(state=>({
                ...state,
                referral: false,
                referralCheck: false,
                parentUsername:''
            }))
        }
        
    }

    const submitReferral =()=>{
        setState(state=>({
            ...state,
            referral: false,
            submitref: true
        }))
    }

    const removeReferral =()=>{
        setState(state=>({
            ...state,
            parentUsername:'',
            submitref: false
        }))
    }

    const termsConditions = () =>{
        setState(state=>({
            ...state,
            terms: !terms
        }))
    }

    const changeBank = (event) => {
        setState({
            ...state,
            settlementBank: event.target.value, 
           settlementAccountNo: '', 
           accountName: '',
        //    nameValidation: false, 
           validAcct: false, 
           validatedButton: false
         })
    }

    const nameEnquiry = () => {
        let reqBody = {
          accountNumber: state.settlementAccountNo,
          bankCode: state.settlementBank
        };
    
        setState({
            ...state,
            // nameValidation: true,
            validatedButton: true,
            disabled:true
        })
        
        axios({
            url:`${baseUrl}/transactions/nameenquiry`,
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            data: reqBody
        })
        .then(validationStatus => {
            setState({
                ...state,
                // nameValidation: false,
                disabled: false,
                validatedButton: false
            })
            if(validationStatus.data.respCode === '999'){
              swal("Failed Operation", `Account Number ${validationStatus.data.respDescription}, please try again`, "error")
              setState({
                ...state,
               accountName: ''
             })
            } else if (validationStatus.data.respCode === '00'){
              setState({
                  ...state,
                  accountName: validationStatus.data.respBody.name, 
                  nameValidation: false
                //   validAcct: true
              })
            } else {
              swal("Failed Attempt", `${validationStatus.data.respDescription}`, "info");
            }
        })
        .catch(err => {
            setState({
                ...state,
                validatedButton: false
            })
            swal("Failed Operation", `${err}`, "info")
        })
    }

    const checkCount = () => {
        if (settlementAccountNo.length >= 10) {
            nameEnquiry()
        }
    }
      // useEffect hook runs when a accountNumber value is changed
      
    useEffect(() => {
        checkCount();
    }, [settlementAccountNo]);


    //Function to select the type of ID to be uploaded
    const onSelectIdType = (value) =>{
        setState(state=>({
            ...state,
            identificationType: value,
        }))
    }

    //Function to cancel the document upload process
    const onCancelDoc = () =>{
        setState(state=>({
            ...state,
            identificationType: '',
            idCard:'',
            idCardBack:''
        }))
    }

    const renderPages = () =>{
        switch(step){
            case 1:
                return <BasicInfo />;
            case 2:
                return <VerifyCode />;
            case 3:
                return <BusinessInfo />;
            case 4:
                return <PersonalInfo />;
            case 5:
                return <VerifyEmail />
            case 6:
                return <BankInfo />;
            case 7:
                return <DocumentUpload />;
            case 8:
                return <UtilityUpload />
            case 9:
                return <Identification />;
            default:
                return <BasicInfo />
        }
    }

    
    const createAgent = (e)=>{
        e.preventDefault();

        setState(state=>({
            ...state,
            submit: true
        }))
        const { address, businessLandmark, businessLga, businessLocation, businessName, businessState, bvn, email, firstName, gender, idCard, 
            idCardBack, idNumber, identificationType, lastName, lga, middleName, parentUsername, activePhoneNumber, picture, settlementAccountNo, settlementBank, signature, stateOfOrigin, utilityBill} = state;

            let reqBody = {
                address,
                businessLandmark,
                businessLga,
                businessLocation,
                businessName,
                businessState,
                bvn,
                email,
                firstName,
                gender,
                idCard,
                idCardBack,
                idNumber,
                identificationType,
                lastName,
                lga,
                middleName,
                parentUsername,
                phoneNumber: activePhoneNumber,
                picture,
                settlementAccountNo,
                settlementBank,
                signature,
                state: stateOfOrigin,
                utilityBill
            };

                axios({
                url:`${baseUrl}/oauth/self-onboard`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: reqBody
                }).then(result => {
                    setState({
                        ...state,
                        submit: false
                    })
                    if(result.data.respCode === '00'){
                        swal("Congratulations", "Freedom account successfully created!, Kindly check your email for more information.", "success");
                        history.push('/');
                    }else {
                        swal("Unsuccessful Operation", `${result.data.respDesc}`, "error") 
                    }
                }).catch(err => {
                    setState({
                        ...state,
                        submit: false
                    })
                    swal("Unsuccessful Operation", `${err}`, "error");
                    // history.push('/');
                })
    }

    return (
        <AgentCreationContext.Provider value={{
            state,
            onClickNext,
            onClickPrev,
            validateBvn,
            verifyOtp,
            onChange,
            cancelAddNumber,
            cancelAddReferral,
            validateEmail,
            changeBank,
            onSelectIdType,
            onCancelDoc,
            onFileChange,
            onCheckReferral,
            submitReferral,
            termsConditions,
            removeReferral,
            createAgent,
            cancelFaq,
            onClickBvn,
            // cloudUpload
        }}>
            
            <Modal open={newPhone} modalClosed={cancelAddNumber}>
                <BvnPhoneInput
                cancelAddNumber={cancelAddNumber}
                /> 
            </Modal>

            <Modal open={referral} modalClosed={cancelAddReferral}>
                <AgentReferral
                cancelAddReferral={cancelAddReferral}
                removeReferral={removeReferral}
                />   
            </Modal>

            <Modal open={faq} className="left-right" modalClosed={cancelFaq}>
                <BvnInfo
                    cancelFaq={cancelFaq}
                />
            </Modal>
            <LoginPageLayoutWrapper>

                    <div className="agent-creation">
                    {renderPages()}
                    </div>
            </LoginPageLayoutWrapper>
        </AgentCreationContext.Provider>

    )
}

export default AgentCreation
